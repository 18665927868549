<template>
  <div>
    <div v-if="isVerificationResent" class="sign-success">
      Verification link was resent successfully! Please check your email.
    </div>
    <AuthContainer
      title="Login"
      :has-error="hasError"
      :loading="isFormSending"
      @submit-form="submitLogin"
    >
      <template #title>
        <h2>Login</h2>
        <p v-if="fromSignUp" class="sign-up-message">
          Please verify your email. <br />
          Account must be verified before logging in.
        </p>
      </template>
      <template #inputs>
        <Input
          v-for="(field, name) in form"
          :key="name"
          v-model="field.value"
          :error="field.error"
          :disabled="isFormSending"
          :name="name"
          :label="field.label"
          :type="name === 'password' ? 'password' : 'text'"
          @input="clearError(name, form)"
          @blur="validateField(name, form)"
        />
      </template>
      <template #actions>
        <div class="form-actions">
          <Link @click="$emit('signUp')">Don't have an account?</Link>
          <Link class="forgot-link" @click="$emit('forgot')"
            >Forgot password?</Link
          >
          <Button style="padding: 5px 20px">Login</Button>
        </div>
      </template>
      <!-- <template #footer>
        <div class="form-footer">
          <span>Or login with</span>
          <ul class="login__social">
            <li class="login__social-item">
              <a
                href="#"
                aria-label="Log in with Google"
                class="login__social-link"
                @click="socialMediaAuth('Google')"
              >
                <img
                  src="../../assets/img/icons/social/google.svg"
                  alt="sessions icon"
                  class="statistic__item-icon"
                />
              </a>
            </li>
          </ul>
        </div>
      </template> -->
      <template #error>
        <Error>
          <template #heading> {{ errorHeader }}</template>
          <template #body>
            <template v-if="isWrongPass">
              Double-check your username and password. You may have used social
              media to login
            </template>
            <template v-else-if="isUnconfirmed">
              Check your email for verification link.
              <button class="error__link" @click="resendVerification">
                Click here
              </button>
              , if you haven’t received a verification link.
            </template>
            <template v-else-if="isUnknownErr || isEmailError">
              Please try again or contact us at
              <a :href="`mailto:${config.helpEmail}`" class="error__link">
                {{ config.helpEmail }}
              </a>
            </template>
          </template>
        </Error>
      </template>
    </AuthContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { transformForm } from "@/assets/js/utils";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import AuthContainer from "./AuthContainer";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";
import Link from "@/components/common/Link";
import Error from "@/components/common/Error";
// import Cookie from "js-cookie";

export default {
  name: "LoginForm",
  props: {
    credsForFilling: {
      type: Object,
      default: () => ({}),
    },
    fromSignUp: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AuthContainer,
    Input,
    Link,
    Error,
    Button,
  },
  data: () => ({
    form: {
      username: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Username",
      },
      password: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Password",
      },
    },
    isFormSending: false,
    hasError: false,
    isWrongPass: false,
    isUnconfirmed: false,
    isUnknownErr: false,
    isEmailError: false,
    isVerificationResent: false,
  }),
  mounted() {
    if (Object.keys(this.credsForFilling).length !== 0) {
      this.form.username.value = this.credsForFilling.username;
      this.form.password.value = this.credsForFilling.password;
    }
    // storing token for redirect after social media login
    this.$store.commit("setTicketToken", this.$route.params.token);
  },
  computed: {
    errorHeader() {
      let text = "Seems there was an issue";

      if (this.isWrongPass) text = "Incorrect username or password";
      if (this.isUnconfirmed) text = "Account hasn’t been confirmed";
      if (this.isEmailError)
        text = "Seems there was an issue resending verification link";

      return text;
    },
  },
  methods: {
    ...mapActions(["signIn", "resendSignUp"]),
    transformForm,
    validateField,
    validateForm,
    clearError,

    submitLogin() {
      const isValid = this.validateForm(this.form);
      if (!isValid) return;

      this.isFormSending = true;
      this.signIn(this.transformForm(this.form))
        .then(() => {
          this.clearErrors();
        })
        .catch((err) => {
          if (err.code) {
            if (err.code === "NotAuthorizedException") this.isWrongPass = true;
            else if (err.code === "UserNotConfirmedException")
              this.isUnconfirmed = true;
          } else {
            this.isUnknownErr = true;
          }

          this.hasError = true;
        })
        .finally(() => {
          this.isFormSending = false;
        });
    },
    // socialMediaAuth(provider = "Google") {
    //   Cookie.set("event_id", this.$route.params.id);
    //   Cookie.set("event_ticket_token", this.$route.params.token);
    //   this.$store.dispatch("signInSocialMedia", provider);
    // },
    clearErrors() {
      if (this.hasError) {
        this.hasError = false;

        if (this.isWrongPass) this.isWrongPass = false;
        if (this.isUnconfirmed) this.isUnconfirmed = false;
        if (this.isUnknownErr) this.isUnknownErr = false;
        if (this.isEmailError) this.isEmailError = false;
      }
    },
    resendVerification(e) {
      e.preventDefault();
      this.clearErrors();
      this.validateField("username", this.form);
      this.resendSignUp({ username: this.form.username.value })
        .then(() => {
          this.isVerificationResent = true;
          setTimeout(() => {
            this.isVerificationResent = false;
          }, 3500);
        })
        .catch(() => {
          this.hasError = true;
          this.isEmailError = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.forgot-link {
  font-size: 18px;
  line-height: 32px;
  text-align: right;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  margin-right: 24px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-footer {
  margin-top: 64px;
  padding-top: 40px;
  border-top: 1px solid $dark-gray;
  position: relative;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: $dark-gray;
    position: absolute;
    top: -17px;
    left: 0;
    right: 0;
    text-align: center;
    background-color: $light-gray;
    width: 146px;
    margin: 0 auto;
  }
}

.login__social {
  list-style-type: none;
  margin-top: 24px;
  padding-left: 0;
  display: flex;
  justify-content: center;

  li {
    display: inline;

    &:not(:last-of-type) {
      margin-right: 24px;
    }
  }
}

.sign-up-message {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  line-height: 22px;
  margin-top: 5px;
}

.error__link {
  color: $error-red !important;
}

::v-deep .form-container {
  margin: 0;
  max-width: unset;
  padding: 16px;

  h2 {
    font-size: 28px;
  }

  .form__title {
    margin-bottom: 16px;
  }

  .form__inputs {
    margin-bottom: 16px;
  }
  .form-actions {
    flex-direction: column;
    row-gap: 16px;
  }
}

@media (min-width: $media-sm) {
  ::v-deep .form-container {
    margin: auto;
    max-width: 70%;
    padding: 48px;

    h2 {
      font-size: 48px;
    }

    .form__title {
      margin-bottom: 48px;
    }

    .form-actions {
      flex-direction: row;
      row-gap: 16px;
    }

    .form__inputs {
      margin-bottom: 48px;
    }
  }
}
</style>
