<template>
  <div>
    <TicketQr />
    <TicketAssociation v-if="!userName" />
  </div>
</template>

<script>
import TicketAssociation from "./TicketAssociation";
import TicketQr from "./TicketQr";

import { mapGetters } from "vuex";

export default {
  name: "UserTicket",
  props: {},
  components: {
    TicketQr,
    TicketAssociation,
  },
  computed: {
    ...mapGetters({
      userName: "getUserName",
    }),
  },
  data: () => ({
    currentView: "qr",
  }),
};
</script>
