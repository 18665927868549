<template>
  <AuthContainer
    title="Sign Up"
    :loading="isFormSending"
    :has-error="hasError"
    @submit-form="submitSignUp"
    class="form-container"
  >
    <template #inputs>
      <Input
        v-for="(field, name) in form"
        :key="name"
        v-model="field.value"
        :error="field.error"
        :disabled="isFormSending"
        :name="name"
        :label="field.label"
        :type="name.toLowerCase().includes('password') ? 'password' : 'text'"
        @input="clearError(name, form)"
        @blur="validateField(name, form)"
      />
    </template>
    <template #actions>
      <div class="form-actions">
        <p>
          By signing up, I agree with
          <Link
            link="/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
          and
          <Link
            link="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Notice
          </Link>
        </p>
        <div class="form-actions__wrapper">
          <Link @click="$emit('goLogin')">Already have an account?</Link>
          <Button style="padding: 5px 20px">Sign Up</Button>
        </div>
      </div>
    </template>
    <template #error>
      <Error>
        <template #heading>
          <div v-if="!doPasswordsMatch" class="sign-up__pass-err">
            Passwords do not match
          </div>
          <div v-else-if="errorMsg">{{ errorMsg }}</div>
          <div v-else>Incorrect username or password</div>
        </template>
        <template #body>
          Double-check your username and password. You may have used social
          media to login.
        </template>
      </Error>
    </template>
  </AuthContainer>
</template>

<script>
import { transformForm } from "@/assets/js/utils";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import AuthContainer from "./AuthContainer";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";
import Link from "@/components/common/Link";
import Error from "@/components/common/Error";
import { mapActions } from "vuex";

export default {
  name: "SignUpForm",
  props: {},
  components: {
    AuthContainer,
    Input,
    Link,
    Error,
    Button,
  },
  data: () => ({
    doPasswordsMatch: true,
    form: {
      username: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Username",
      },
      email: {
        value: "",
        error: "",
        rules: ["required", "email"],
        label: "Email",
      },
      password: {
        value: "",
        error: "",
        rules: ["required", "password"],
        type: "password",
        label: "Password",
      },
      confirmPassword: {
        value: "",
        error: "",
        rules: ["required", "password"],
        label: "Confirm password",
      },
    },
    hasError: false,
    errorMsg: "",
    isFormSending: false,
  }),
  computed: {},
  methods: {
    ...mapActions({
      register: "signUp",
    }),
    transformForm,
    validateField,
    validateForm,
    clearError,

    submitSignUp() {
      const isValid = this.validateForm(this.form);
      if (!isValid) return;
      this.doPasswordsMatch =
        this.form.password.value === this.form.confirmPassword.value;
      if (!this.doPasswordsMatch) return;

      const creds = this.transformForm(this.form);
      delete creds.confirmPassword;

      this.isFormSending = true;
      this.register(creds)
        .then(() => {
          this.$emit("registered", creds);
        })
        .catch((err) => {
          this.hasError = true;
          this.errorMsg = err.response.data.msg || "";
        })
        .finally(() => {
          this.isFormSending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.login__social {
  list-style-type: none;
  margin-top: 24px;
  padding-left: 0;

  li {
    display: inline;
    &:not(:last-of-type) {
      margin-right: 24px;
    }
  }
}

.form-actions {
  p {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
  }
  &__wrapper {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
  }
}

.form-container {
  margin: auto;
}

.form-container {
  margin: 0;
  max-width: unset;
  padding: 16px;

  ::v-deep h2 {
    font-size: 28px;
  }

  ::v-deep .form__title {
    margin-bottom: 16px;
  }

  .form__inputs {
    margin-bottom: 16px;
  }
  .form-actions__wrapper {
    flex-direction: column;
    row-gap: 16px;
  }
}

@media (min-width: $media-sm) {
  .form-container {
    margin: auto;
    max-width: 70%;
    padding: 48px;

    ::v-deep h2 {
      font-size: 48px;
    }

    ::v-deep .form__title {
      margin-bottom: 48px;
    }

    .form-actions__wrapper {
      flex-direction: row;
      row-gap: 16px;
    }

    .form__inputs {
      margin-bottom: 48px;
    }
  }
}
</style>
