<template>
  <div class="qrpage padding-x">
    <Heading noDecoration type="h3" text-align="center" class="qrpage__title">
      Your ticket for<br />
      {{ event.name }}
    </Heading>
    <Preloader :loading="isOrderLoading" />

    <div v-if="ticket" class="qrpage__ticket">
      <qrcode-vue :value="qrValue" :size="size" level="H" class="ma-6" />
      <div>
        <div class="qrpage__level">{{ ticket.level }} level</div>
        <dl>
          <dd
            v-for="feature in ticket.description"
            :key="feature.feature"
            class="ticket__feature"
          >
            {{ feature.feature }}
          </dd>
        </dl>
      </div>
    </div>
    <p class="virtual-conference padding-x" v-if="userName">
      Attending virtual conference only? Continue to
      <a :href="`${baseUrl}virtualconf/#/${$route.params.id}`">
        Virtual Conference Platform
      </a>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";

import QrcodeVue from "qrcode.vue";

export default {
  name: "TicketQr",
  props: {},
  components: {
    Preloader,
    Heading,
    QrcodeVue,
  },
  data: () => ({
    isOrderLoading: false,
    ticket: null,
    qrValue: null,
    size: 235,
  }),
  computed: {
    ...mapGetters({
      event: "getCurrentEventDetails",
      userName: "getUserName",
    }),
    ...mapState(["currentEventID"]),
    baseUrl() {
      return process.env.VUE_APP_BASE_URL.split("api/")[0];
    },
    eventId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.isOrderLoading = true;
    this.getTicket();

    if (!Object.keys(this.event).length) {
      this.isOrderLoading = true;
      this.getEvent(this.eventId || this.currentEventID)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isOrderLoading = false;
        });
    }
  },
  methods: {
    ...mapActions(["getEvent", "GET"]),
    getTicket() {
      return this.GET({
        route: `/ticketing/public/event/${
          this.eventId || this.currentEventID
        }/ticket/${this.$route.params.token}`,
      })
        .then((resp) => {
          this.ticket = resp.data;
          this.qrValue = resp.data.id;
          this.isOrderLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isOrderLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.virtual-conference {
  padding: 28px;
  width: 70%;
  margin: auto;
  border: 1px solid $dark-gray;
}

.qrpage {
  padding-top: 108px;

  &__title {
    margin-bottom: 21px;
  }

  &__ticket {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  &__level {
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: $black;
  }
}

.apps {
  background-color: $light-gray;
  letter-spacing: 0.05em;
  margin: 120px 0;

  &__title {
    font-family: $sarabun;
    font-weight: $sarabunBold;
    font-size: 24px;
    letter-spacing: 0.08em;
    color: $dark-gray;
  }
}

@media (min-width: $media-xs) {
  .qrpage {
    text-align: center;
  }
}

@media (min-width: $media-sm) {
  .qrpage {
    &__ticket {
      width: 80%;
      margin: 50px auto 10px;
      padding: 25px;
      border: 1px solid $gray;
      flex-direction: row;
      justify-content: space-evenly;
      text-align: left;
    }
  }

  .apps {
    margin: 127px 0 260px 0;
    padding: 124px 0 136px 0;

    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }
}

@media (min-width: $media-lg) {
  .qrpage {
    &__ticket {
      width: 70%;
    }
  }

  .apps {
    &__title {
      font-size: 40px;
      line-height: 56px;
      margin-bottom: 28px;
    }
  }
}
</style>
